<template>
  <div class="spacer"></div>
</template>
<script>
export default {
  name: "SpacerMd",
  props: {},
};
</script>
<style>
.spacer {
  display: block;
  height: 14vh;
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .spacer {
    height: 30vh;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .spacer {
    height: 15vh;
  }
}
</style>