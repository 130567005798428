<template>
  <div id="textbox">
    <b-jumbotron
      header=""
      lead=""
      class="bg-dark text-white fluid bring-to-front mt-3"
      text-center
    >
      <h2 v-html="texts.head"></h2>
      <div v-html="texts.text"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col text-right">
            <button-medium
              :buttonText="texts.buttontext"
              @click.native="nextRound"
            ></button-medium>
          </div>
        </div>
      </div>
    </b-jumbotron>
  </div>
</template>
<script>
import json from "../../assets/content/textboxtexts.json";
import ButtonMedium from "../atoms/ButtonMedium.vue";
export default {
  name: "TextBox",
  components: {
    ButtonMedium,
  },
  data: function () {
    return {
      roundId: 0,
      // arrLength: this.json.length,
    };
  },
  props: {},
  methods: {
    nextRound: function () {
      if (this.roundId <= this.textLenght) {
        //TODO: replace with Mutation
        this.$store.state.showBox = "test";
        this.roundId++;
      } else {
        this.roundId = 0;
        //TODO: replace with Mutation
        this.$store.state.showBox = "end";
      }
    },
  },
  computed: {
    texts() {
      // return json.infotexts[this.roundId];
      return json.infotexts[this.$store.state.round - 1];
    },
    textLenght() {
      return json.infotexts.length;
    },
  },
  mounted: function () {},
};
</script>
<style>
#textbox .display-3 {
  font-size: 2.5rem;
}
#textbox {
  max-width: 90vw;
  margin: 0px auto;
}
</style>