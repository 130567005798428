<template>
  <div id="app">
    <header-nav></header-nav>
    <test-page></test-page>
    <footer-centered></footer-centered>
  </div>
</template>

<script>
import FooterCentered from "./components/molecules/FooterCentered.vue";
import HeaderNav from "./components/molecules/HeaderNav.vue";
import TestPage from "./components/pages/TestPage.vue";
export default {
  name: "App",
  components: {
    HeaderNav,
    TestPage,
    FooterCentered,
  },
  data() {
    return {};
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap");
/* Body */
body {
  background-color: rgb(44, 62, 79) !important;
  color: rgb(238, 238, 238) !important;
}
#app {
  font-family: "Roboto", sans-serif;
}
</style>
