<template>
  <div id="ButtonArea" class="container-fluid">
    <div class="row">
      <button
        :disabled="this.$store.state.error"
        class="col text-center mr-4 ml-4 button-bgcolor"
        @click="
          buttonClickedLeft();
          validateClick();
        "
        :id="this.getButtonIdL()"
      >
        <h2>Left Button</h2>
      </button>
      <button
        :disabled="this.$store.state.error"
        class="col text-center ml-4 mr-4 button-bgcolor"
        @click="
          buttonClickedRight();
          validateClick();
        "
        :id="this.getButtonIdR()"
      >
        <h2>Right Button</h2>
      </button>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "ButtonArea",
  props: {},
  methods: {
    ...mapMutations([
      "nextStim",
      "setButtonCLicked",
      "setCurrentClick",
      "changeErrorStatus",
      "changeButtonStatus",
      "setTimerEnd",
      "setDuration",
      "pushLogarray",
      "setDate",
      "setResult",
      "pushResults",
    ]),
    ...mapGetters(["getButtonIdL", "getButtonIdR"]),
    buttonClickedLeft() {
      this.setButtonCLicked("left");
      this.setCurrentClick(this.$store.state.buttonIdL);
    },
    buttonClickedRight() {
      this.setButtonCLicked("right");
      this.setCurrentClick(this.$store.state.buttonIdR);
    },
    postDataAxios() {
      this.submitting = true;
      // this.$store.state.logArray.forEach((item) => console.log(item));
      axios
        .post(
          "https://iat-logs-default-rtdb.europe-west1.firebasedatabase.app/logs.json",
          {
            Test_Code: this.$store.state.testId,
            Status: this.$store.state.testStatus,
            Stimulus_Code: this.$store.state.stimuliCode,
            Stimulus: this.$store.state.stimuli,
            Attribute_Side: this.$store.state.attributeSide,
            Clicked_Button: this.$store.state.buttonClicked,
            Congruence: this.$store.state.congruence,
            Result: this.$store.state.result,
            Duration: this.$store.state.duration,
            Date: this.$store.state.date,
          }
        )
        .then((response) => {
          console.log(response);
          this.submitting = false;
        });
    },
    //checks if click was correct and displays error if not
    validateClick() {
      this.setTimerEnd(performance.now());
      this.setDuration();
      console.log("Stop: " + this.$store.state.timerEnd);
      console.log("Duration: " + this.$store.state.duration);
      switch (this.$store.state.buttonClicked) {
        case "left":
          console.log("Left Button");
          if (
            this.$store.state.leftCategory === this.$store.state.stimuliTag ||
            this.$store.state.leftAttribute === this.$store.state.stimuliTag
          ) {
            console.log("Correct");
            this.changeErrorStatus(false);
            this.setResult("correct");
            this.changeButtonStatus(false);
            this.pushLogarray(this.logsSring);
            this.pushResultsPost();
            setTimeout(
              () => (this.changeButtonStatus(true), this.nextStim()),
              100
            );
          } else {
            console.log("Wrong");
            this.changeButtonStatus(false);
            this.setResult("wrong");
            this.changeErrorStatus(true);
            this.pushLogarray(this.logsSring);
            this.pushResultsPost();
            setTimeout(
              () => (
                this.changeErrorStatus(false),
                this.nextStim(),
                this.changeButtonStatus(true)
              ),
              490
            );
          }
          break;
        case "right":
          if (
            this.$store.state.rightCategory === this.$store.state.stimuliTag ||
            this.$store.state.rightAttribute === this.$store.state.stimuliTag
          ) {
            this.setResult("correct");
            this.changeErrorStatus(false);
            this.changeButtonStatus(false);
            this.pushLogarray(this.logsSring);
            this.pushResultsPost();
            console.log("Correct");
            setTimeout(
              () => (this.changeButtonStatus(true), this.nextStim()),
              100
            );
          } else {
            console.log("Wrong");
            this.setResult("wrong");
            this.changeErrorStatus(true);
            this.changeButtonStatus(false);
            this.pushLogarray(this.logsSring);
            this.pushResultsPost();
            setTimeout(
              () => (
                this.changeErrorStatus(false),
                this.nextStim(),
                this.changeButtonStatus(true)
              ),
              490
            );
          }
          break;
        default:
          console.log("Error: Error in ButtonArea.vue validate Click");
      }
      console.log(":-(" + this.$store.state.buttonClicked);
      console.log("Array: " + this.$store.state.logArray);
      console.log("JSON: " + JSON.stringify(this.$store.state.logArray));
      console.log(
        "JSON parsed: " + JSON.parse(JSON.stringify(this.$store.state.logArray))
      );
    },
    pushResultsPost() {
      this.pushResults(this.logs);
      this.postDataAxios();
    },
  },
  computed: {
    logsSring() {
      // var obj = JSON.parse(this.$store.state.logArray);
      // obj.push(this.logs);
      // this.$store.state.logArray = JSON.stringify(obj);

      // var j = JSON.stringify(this.logs);
      // JSON.parse(j);
      // console.log("JSON: " + j);
      // return JSON.stringify(this.logs);
      return JSON.stringify(this.logs);
    },
    currentDate() {
      this.setDate();
      console.log("Date" + this.$store.state.date);
      return this.$store.state.date;
    },
    logs() {
      return {
        Test_Code: this.$store.state.testId,
        Status: this.$store.state.testStatus,
        Stimulus_Code: this.$store.state.stimuliCode,
        Stimulus: this.$store.state.stimuli,
        Attribute_Side: this.$store.state.attributeSide,
        Clicked_Button: this.$store.state.buttonClicked,
        Congruence: this.$store.state.congruence,
        Result: this.$store.state.result,
        Duration: this.$store.state.duration,
        Date: this.currentDate,
      };
    },
  },
};
</script>
<style>
#ButtonArea {
  max-width: 90vw;
}
#ButtonArea .col {
  border: 1px solid rgb(90 90 90);
  padding-bottom: 35px;
  padding-top: 35px;
  background-color: rgba(255, 255, 255, 0.055);
  border-radius: 0.3rem;
  color: rgba(216, 216, 216, 0.301);
}
.button-bgcolor {
  background-color: rgb(66, 88, 108) !important;
}
</style>