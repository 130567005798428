<template>
  <div id="AttributeBox" class="container-fluid">
    <div class="row">
      <div class="col text-center mr-2 no-b-border" :id="catTagL">
        <h3>{{ catL }}</h3>
      </div>
      <div class="col text-center ml-2 no-b-border" :id="catTagR">
        <h3>{{ catR }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col text-center mr-2 no-t-border" :id="attTagL">
        <h3>{{ attL }}</h3>
      </div>
      <div class="col text-center ml-2 no-t-border" :id="attTagR">
        <h3>{{ attR }}</h3>
      </div>
    </div>
  </div>
</template>
<script>
import json from "../../assets/content/attributes.json";
import { mapMutations } from "vuex";
export default {
  name: "AttributeBox",
  //get path from IAT.vue
  props: {
    path: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // randomInt: 0,
      // attJson: json,
      attA: json.attributes[0].att,
      attB: json.attributes[1].att,
      catA: json.categories[0].cat,
      catB: json.categories[1].cat,
      attTagA: json.attributes[0].atttag,
      attTagB: json.attributes[1].atttag,
      catTagA: json.categories[0].cattag,
      catTagB: json.categories[1].cattag,
      catL: "",
      catR: "",
      attL: "",
      attR: "",
      catTagL: "",
      catTagR: "",
      attTagL: "",
      attTagR: "",
    };
  },
  mounted: function () {
    // this.setRandomVariant();
    this.setPathState();
    this.setIatState();
    if (this.$store.state.round === 1) {
      console.log("setIatStage 1");
      this.setButtonIdL(this.catTagL);
      this.setButtonIdR(this.catTagR);
    } else {
      console.log("setIatStage >1");
      this.setButtonIdL(this.attTagL);
      this.setButtonIdR(this.attTagR);
    }
    this.checkForCongruence();
    console.log("Button ID left: " + this.$store.state.buttonIdL);
    console.log("Button ID right: " + this.$store.state.buttonIdR);
    // console.log("Path passed: " + this.path);
  },
  methods: {
    ...mapMutations([
      "setButtonIdL",
      "setButtonIdR",
      "setLeftAttribute",
      "setRightAttribute",
      "setLeftCategory",
      "setRightCategory",
      "setCongruenceState",
      "setTestStatus",
      "setAttributeSide",
    ]),
    //Checks, if the test is congruent or not
    checkForCongruence() {
      if (
        (this.$store.state.leftAttribute === "sex" &&
          this.$store.state.leftCategory === "koop") ||
        (this.$store.state.rightAttribute === "sex" &&
          this.$store.state.rightCategory === "koop")
      ) {
        this.setCongruenceState("kongruent");
      } else {
        this.setCongruenceState("nicht kongruent");
      }
    },
    checkAttributeSide() {
      console.log("checkAttrbiuteSide:");
      if (this.$store.state.leftAttribute === "sex") {
        this.setAttributeSide("left");
        console.log("Set AttrbiuteSide Left" + this.$store.state.attributeSide);
      } else {
        this.setAttributeSide("right");
        console.log(
          "Set AttrbiuteSide Right: " + this.$store.state.attributeSide
        );
      }
    },
    //Here it is randomly determined in the given scheme in which order and on which position the attributes and categories are displayed.
    setPathState() {
      switch (this.path) {
        case "A":
          console.log("A");
          (this.catL = this.catA) && (this.catTagL = this.catTagA),
            (this.catR = this.catB) && (this.catTagR = this.catTagB),
            (this.attL = this.attA) && (this.attTagL = this.attTagA),
            (this.attR = this.attB) && (this.attTagR = this.attTagB);
          break;
        case "B":
          console.log("B");
          (this.catL = this.catB) && (this.catTagL = this.catTagB),
            (this.catR = this.catA) && (this.catTagR = this.catTagA),
            (this.attL = this.attA) && (this.attTagL = this.attTagA),
            (this.attR = this.attB) && (this.attTagR = this.attTagB);
          break;
        case "C":
          console.log("C");
          (this.catL = this.catA) && (this.catTagL = this.catTagA),
            (this.catR = this.catB) && (this.catTagR = this.catTagB),
            (this.attL = this.attB) && (this.attTagL = this.attTagB),
            (this.attR = this.attA) && (this.attTagR = this.attTagA);
          break;
        case "D":
          console.log("D");
          (this.catL = this.catB) && (this.catTagL = this.catTagB),
            (this.catR = this.catA) && (this.catTagR = this.catTagA),
            (this.attL = this.attB) && (this.attTagL = this.attTagB),
            (this.attR = this.attA) && (this.attTagR = this.attTagA);
          break;
      }
    },
    //this is for a single iat, more states could be added
    setIatState() {
      switch (this.$store.state.round) {
        case 1:
          this.setLeftCategory(this.catTagL);
          this.setRightCategory(this.catTagR);
          this.setLeftAttribute(this.attTagL);
          this.setRightAttribute(this.attTagR);
          this.attL = "";
          this.attR = "";
          this.setTestStatus("Training");
          this.checkAttributeSide();
          console.log("case 1");
          break;
        case 2:
          console.log("case 2");
          // this.setButtonIdL(this.attTagL);
          // this.setButtonIdR(this.attTagR);
          this.setTestStatus("Training");
          this.checkAttributeSide();
          break;
        case 3:
          console.log("case 3");
          this.setTestStatus("Test");
          this.checkAttributeSide();
          // this.setButtonIdL(this.attTagL);
          // this.setButtonIdR(this.attTagR);
          break;
        case 4:
          console.log("case 4");
          [this.attL, this.attR] = [this.attR, this.attL];
          [this.attTagL, this.attTagR] = [this.attTagR, this.attTagL];
          this.setLeftAttribute(this.attTagL);
          this.setRightAttribute(this.attTagR);
          this.setTestStatus("Training");
          this.checkAttributeSide();
          // this.setButtonIdL(this.attTagL);
          // this.setButtonIdR(this.attTagR);
          break;
        case 5:
          console.log("case 5");
          [this.attL, this.attR] = [this.attR, this.attL];
          [this.attTagL, this.attTagR] = [this.attTagR, this.attTagL];
          this.setTestStatus("Test");
          this.checkAttributeSide();
          // this.setButtonIdL(this.attTagL);
          // this.setButtonIdR(this.attTagR);
          break;
        default:
          console.log("Error: Status error in AttribueBox.vue");
      }
    },
  },
};
</script>
<style>
/* Attribute Text Box*/
#AttributeBox {
  margin-top: 6vh;
  max-width: 90vw;
  z-index: 1000 !important;
}
#AttributeBox .col {
  border: 1px solid rgb(90 90 90 / 47%);
  padding-bottom: 15px;
  padding-top: 15px;
}
.no-b-border {
  border-bottom: 0 !important;
}
.no-t-border {
  border-top: 0 !important;
}
</style>
