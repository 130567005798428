<template>
  <div>
    <div
      v-show="this.$store.state.error === false"
      id="StimulusBox"
      class="container-fluid"
    >
      <div class="row">
        <div class="col text-center">
          <h2>{{ stimuli }}</h2>
        </div>
      </div>
    </div>
    <div
      v-show="this.$store.state.error === true"
      id="ErrorBox"
      class="container-fluid"
    >
      <div class="row">
        <div class="col text-center">
          <h2><span class="error">Fehler!</span></h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import json from "../../assets/content/stimuli-test.json";
// import json from "../../assets/content/stimuli.json";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "StimulusBox",
  props: {},
  data() {
    return {
      //Set json head to select, connect with property
      // stimuliSelect: "StimuliB",
      //TODO: bind to property to selct stimulus
      //nmbr: this.$store.state.stimcount,
    };
  },
  beforeMount() {
    // console.log("Stimuli shuffled");
    this.shuffeldStimuli(this.stimuliArr);
    //sets lenghth in store to length in json stimuli array
    this.$store.commit("updatestimLenghtState", this.stimLenght);
  },
  methods: {
    ...mapActions(["updatestimLenghtState"]),
    ...mapGetters(["getStimulus"]),
    ...mapMutations([
      "setStimuliWord",
      "setStimuliTag",
      "setStimuliCode",
      "setTimerStart",
    ]),
    //Shuffles Stimulus Array based on Fisher-Yates Shuffling Algorithm and this website: https://www.jstips.co/en/javascript/shuffle-an-array/ (30.1.2021)
    shuffeldStimuli(array) {
      var currentIndex = this.stimLenght,
        temp,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temp = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temp;
      }
      return array;
    },
  },
  computed: {
    //selects array from json
    stimuliArr() {
      return json[this.stimuliSelect];
    },
    //counts lenghth of array
    stimLenght() {
      return this.stimuliArr.length;
    },
    //returns stimcount from store
    nmbr() {
      return this.$store.state.stimcount;
    },
    //selects stimuli from array
    stimuli() {
      var stim = json[this.stimuliSelect][this.nmbr].word;
      this.setStimuliWord(stim);
      var tag = json[this.stimuliSelect][this.nmbr].tag;
      this.setStimuliTag(tag);
      var code = json[this.stimuliSelect][this.nmbr].stimulusCode;
      this.setStimuliCode(code);
      //get back stimulus from store
      stim = this.getStimulus();
      // Set Start Time
      this.setTimerStart(performance.now());
      console.log("Start Time" + this.$store.state.timerStart);
      return stim;
    },
    stimuliSelect() {
      if (this.$store.state.round === 1) {
        return "StimuliA";
      } else {
        return "StimuliB";
      }
    },
    getErrorStatus() {
      return false;
    },
  },
};
</script>
<style>
#StimulusBox,
#ErrorBox {
  max-width: 90vw;
}
#StimulusBox,
#ErrorBox .col {
  margin-top: 5vh;
  border: 1px solid rgb(90 90 90 / 47%);
  padding-bottom: 25px;
  padding-top: 25px;
}
.error {
  color: red;
}
</style>