<template>
  <div>
    <b-button variant="primary" href="#" :id="this.buttonID">{{
      buttonText
    }}</b-button>
  </div>
</template>
<script>
export default {
  //components: { ButtonMd },
  name: "ButtonMedium",
  props: {
    buttonText: {
      type: String,
      default: "Weiter",
    },
    buttonID: {
      type: String,
      default: "button",
    },
  },
  data: function () {
    return {};
  },
};
</script>