<template>
  <div>
    <StartBox v-if="this.$store.state.showBox === 'start'"></StartBox>
    <TextBox v-if="this.$store.state.showBox === 'info'"></TextBox>
    <EndBox v-if="this.$store.state.showBox === 'end'"></EndBox>
    <LogFiles v-if="this.$store.state.showBox === 'logs'"></LogFiles>
    <div id="content-container">
      <AttributeBox
        v-if="this.$store.state.showBox === 'test'"
        :path="this.path"
      ></AttributeBox>
      <StimulusBox v-if="this.$store.state.showBox === 'test'"></StimulusBox>
      <spacer-md v-if="this.$store.state.showBox === 'test'"></spacer-md>
      <ButtonArea v-if="this.$store.state.showBox === 'test'"></ButtonArea>
    </div>
  </div>
</template>
<script>
import StartBox from "../organisms/StartBox.vue";
import LogFiles from "../organisms/LogFiles.vue";
import TextBox from "../organisms/TextBox.vue";
import EndBox from "../organisms/EndBox.vue";
import AttributeBox from "../organisms/AttributeBox.vue";
import ButtonArea from "../organisms/ButtonArea.vue";
import StimulusBox from "../organisms/StimulusBox.vue";
import SpacerMd from "../atoms/SpacerMd.vue";
export default {
  name: "IatPage",
  components: {
    StartBox,
    TextBox,
    EndBox,
    AttributeBox,
    ButtonArea,
    StimulusBox,
    SpacerMd,
    LogFiles,
  },
  mounted: function () {
    // console.log("Mounted IAT-page");
    this.setRandomPath();
  },
  props: {},
  methods: {
    //set randomly the path for showing attributes/categories for AttributeBox.vue
    setRandomPath() {
      let min = Math.ceil(1);
      let max = Math.floor(4);
      var randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
      // console.log("RND: " + randomInt);
      if (randomInt == 1) {
        this.path = "A";
        // console.log("PATH: " + this.path);
      } else if (randomInt == 2) {
        this.path = "B";
        // console.log("PATH: " + this.path);
      } else if (randomInt == 3) {
        this.path = "C";
        // console.log("PATH: " + this.path);
      } else {
        this.path = "D";
        // console.log("PATH: " + this.path);
      }
    },
  },
  data() {
    return {
      path: "",
    };
  },
};
</script>
<style>
/* Content container */
#content-container {
  margin: 0px auto;
  max-width: 90vw;
}
</style>