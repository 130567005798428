<template>
  <div>
    <alert-box v-if="this.$store.state.showBox === 'test'"></alert-box>
    <iat-page></iat-page>
  </div>
</template>
<script>
// import TestJson from "./components/molecules/TestJson.vue";
import AlertBox from "../molecules/AlertBox.vue";
import IatPage from "../templates/IatPage.vue";
export default {
  name: "TestPage",
  components: {
    AlertBox,
    // TestJson,
    IatPage,
  },
  mounted: function () {},
};
</script>