<template>
  <footer class="container-fluid">
    <div class="row">
      <div class="col text-center">
        <p>&copy; 2021 Dominic Hofmeister</p>
      </div>
    </div>
  </footer>
</template>
<script>
//import ButtonMd from "../atoms/ButtonMd.vue";
export default {
  //components: { ButtonMd },
  name: "FooterCentered",
  props: {},
};
</script>
<style>
footer {
  margin-top: 7vh;
  padding-top: 15px;
  color: rgba(202, 202, 202, 0.664);
  font-size: 0.8rem;
}
</style>