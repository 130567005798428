<template>
  <div id="textbox">
    <b-jumbotron
      header=""
      lead=""
      class="bg-dark text-white fluid bring-to-front mt-3"
      text-center
    >
      <h2 v-html="texts.head"></h2>
      <div v-html="texts.text"></div>
      <p v-if="submitting">Sende Daten an Datenbank...</p>
      <p v-if="!submitting">Daten an Datenbank gesendet.</p>
      <div class="container-fluid">
        <div class="row">
          <div class="col text-right">
            <download-csv :data="json_data" :name="fileName">
              <button-medium buttonText="Save to disk"></button-medium>
            </download-csv>
            <!-- <button-medium
              :buttonText="texts.buttontext"
              @click.native="endIat"
              v-show="showEnd"
            ></button-medium> -->
          </div>
        </div>
      </div>
    </b-jumbotron>
  </div>
</template>
<script>
import json from "../../assets/content/textboxtexts.json";
import ButtonMedium from "../atoms/ButtonMedium.vue";
import axios from "axios";
export default {
  name: "EndBox",
  components: {
    ButtonMedium,
  },
  data: function () {
    return {
      submitting: false,
    };
  },
  props: {},
  methods: {
    endIat: function () {
      this.$store.state.showBox = "";
    },
    // postDataAxios() {
    //   this.submitting = true;
    //   // this.$store.state.logArray.forEach((item) => console.log(item));
    //   axios
    //     .post(
    //       "https://iat-logs-default-rtdb.europe-west1.firebasedatabase.app/logs.json",
    //       {
    //         logs: this.$store.state.logArray,
    //       }
    //     )
    //     .then((response) => {
    //       console.log(response);
    //       this.submitting = false;
    //     });
    // },
    postDataAxios() {
      this.submitting = true;
      // this.$store.state.logArray.forEach((item) => console.log(item));
      axios
        .post(
          "https://iat-logs-default-rtdb.europe-west1.firebasedatabase.app/logs.json",
          {
            testCode: this.$store.state.logResults[0].Test_Code,
            Stimulus: this.$store.state.logResults[0].Stimulus,
          }
        )
        .then((response) => {
          console.log(response);
          this.submitting = false;
        });
    },
    postDataFetch() {
      fetch(
        "https://iat-logs-default-rtdb.europe-west1.firebasedatabase.app/logs.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            testcode: this.$store.state.testId,
            // stimulus: this.$store.state.stimuli,
          }),
        }
      );
    },
  },
  computed: {
    roundID() {
      return 0;
    },
    texts() {
      // return json.infotexts[this.roundId];
      return json.endboxtext[this.roundID];
    },
    textLenght() {
      return json.endboxtext.length;
    },
    json_data() {
      var s = JSON.parse(JSON.stringify(this.$store.state.logArray));
      s = "[" + s + "]";
      JSON.stringify(s);
      return JSON.parse(s);
    },
    fileName() {
      var name = this.$store.state.date.toString() + "_IAT-log.csv";
      return name;
    },
  },
  mounted: function () {
    // this.postDataAxios();
    // this.postDataFetch();
  },
};
</script>
<style>
#textbox .display-3 {
  font-size: 2.5rem;
}
#textbox {
  max-width: 90vw;
  margin: 0px auto;
}
</style>