<template>
  <div id="logtable">
    <div class="container-fluid">
      <h1>Testergebnisse</h1>

      <b-input placeholder="Input Filter String" v-model="filterRecord" />
      <b-button @click="clearFilter()" variant="info" class="mb-2"
        >Clear Filter</b-button
      >
      <b-button @click="fetchRecords()" variant="success" class="mb-2"
        >Reload Table</b-button
      >
      <br />
      <b-table
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :bordered="bordered"
        :outlined="outlined"
        :hover="hover"
        :striped="striped"
        responsive="true"
        :items="results"
        :small="small"
        :fields="fields"
        :tableVariant="tableVariant"
        :head-variant="headVariant"
        :filter="filterRecord"
        :sticky-header="stickyHeader"
        id="rec-table"
      ></b-table>

      <!-- <ul>
      <li v-for="result in results" v-bind:key="result.id">
        {{ "ID: " + result.id }}, {{ "TestCode: " + result.testCode }}
      </li>
    </ul> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "LogFiles",
  data: function () {
    return {
      sortBy: "timeStampTable",
      sortDesc: false,
      records: "",
      items: [],
      tableVariants: ["info"],
      striped: true,
      bordered: true,
      outlined: true,
      hover: true,
      fixed: "responsive",
      footClone: false,
      filterRecord: "",
      headVariant: "dark",
      tableVariant: "dark",
      stickyHeader: false,
      small: true,
      fields: [
        // { key: "id", sortable: true },
        { key: "Test_Code", sortable: true },
        { key: "Status", sortable: true },
        { key: "Stimulus_Code", sortable: true },
        { key: "Stimulus", sortable: true },
        { key: "Attribute_Side", sortable: true },
        { key: "Clicked_Button", sortable: true },
        { key: "Congruence", sortable: true },
        { key: "Result", sortable: true },
        { key: "Duration", sortable: true },
        { key: "Date", sortable: true },
      ],
      results: [],
    };
  },
  mounted: function () {
    this.fetchRecords();
  },
  methods: {
    clearFilter: function () {
      this.filterRecord = "";
    },
    fetchRecords: function () {
      console.log("FETCH entered");
      fetch(
        "https://iat-logs-default-rtdb.europe-west1.firebasedatabase.app/logs.json"
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          console.log(data);
          const results = [];
          for (const id in data) {
            results.push({
              id: id,
              Test_Code: data[id].Test_Code,
              Status: data[id].Status,
              Stimulus_Code: data[id].Stimulus_Code,
              Stimulus: data[id].Stimulus,
              Attribute_Side: data[id].Attribute_Side,
              Clicked_Button: data[id].Clicked_Button,
              Congruence: data[id].Congruence,
              Result: data[id].Result,
              Duration: data[id].Duration,
              Date: data[id].Date,

              //   status: data[id].Status,
            });

            // console.log("Results: " + this.results);
          }
          this.results = results;
          console.log("Results: " + JSON.stringify(this.results));
        });
    },
    loadRecords: function () {
      //   let that = this;
      console.log("Records");
      axios
        .get(
          "https://iat-logs-default-rtdb.europe-west1.firebasedatabase.app/logs.json"
        )
        .then(function (response) {
          return response.json();
          //   console.log("Data recieved:" + response);

          //   that.records = response;
          //   that.records = JSON.stringify(that.records);
          //   that.records = JSON.parse(JSON.stringify(response));
          //   that.records = JSON.stringify(that.records);
          //   that.records = JSON.stringify(response.data);
          //   that.items = response.data.data;
          //   console.log(that.records);
          //   console.log(that.records);
        });
    },
  },
};
</script>
<style>
.table-words {
  table-layout: fixed;
  over-flow: break-word;
}
#rec-table {
  height: 100vh;
}
</style>
    